import React, { Component } from 'react';
import * as Colyseus from "colyseus.js";
import Lottie from 'react-lottie';
import { Howl, Howler } from "howler";

import getAvatarById from "constants/avatars";

import "animate.css";
import styles from 'components/TutorialStyles.module.scss';

import CopyPlayerIcon from "images/copy-player.png";
import DoublePointsIcon from "images/double-points.png";
import RemoveOneIcon from "images/remove-one.png";


var audio = {

};


export default class Tutorial extends Component {
    static displayName = Tutorial.name;

    constructor(props) {
        super(props);

        this.client = new Colyseus.Client(process.env.REACT_APP_GAME_SERVER_URL);
        this.state = {
            showStepOne: true,
            showStepOneTextOne: false,
            showStepOneTextTwo: false,
            stepOneTextOne: "Grab a drink...",
            stepOneTextTwo: "...it's time for a Pub Quiz! \n ",
            stepOnePlayers: [
                {
                    avatar: 2,
                    show: false,
                },
                {
                    avatar: 8,
                    show: false,
                },
                {
                    avatar: 10,
                    show: false,
                },
                {
                    avatar: 6,
                    show: false,
                }
            ],

            showStepTwo: true,
            showStepTwoText: false,
            showStepTwoCategories: false,
            stepTwoCategories: [
                "General Knowledge",
                "Natural World",
                "Film & TV",
                "History & Politics",
                "Culture",
                "Sport",
                "Science",
            ],
            stepTwoSelectedCategory: "Film & TV",

            showStepThree: true,
            showStepThreeText: false,
            showStepThreeQuestion: false,
            showStepThreeAnswers: false,
            questionNumber: 1,
            question: {
                question: "What is the capital of France?",
                answers: [
                    {
                        answer: "Paris",
                        isCorrect: true,
                    },
                    {
                        answer: "London",
                        isCorrect: false,
                    },
                    {
                        answer: "Berlin",
                        isCorrect: false,
                    },
                ]
            },
            stepThreePlayers: [
                {
                    avatar: 2,
                    pubData: {
                        answer: "Paris",
                    }
                },
                {
                    avatar: 8,
                    pubData: {
                        answer: "London",
                    }
                },
                {
                    avatar: 10,
                    pubData: {
                        answer: "Berlin",
                    }
                },
                {
                    avatar: 6,
                    pubData: {
                        answer: "Paris",
                    }
                }
            ],
            showCorrectAnswer: false,
            showPlayerAnswers: false,

            showStepFour: true,
            showStepFourTextOne: false,
            showStepFourTextTwo: false,
            showStepFourMechanics: false,
            StepFourMechanics: [
                //{
                //    text: "Copy a players answer",
                //    icon: CopyPlayerIcon
                //},
                {
                    text: "Double your points",
                    icon: DoublePointsIcon
                },
                {
                    text: "Remove one answer",
                    icon: RemoveOneIcon
                },
            ],

            showStepFive: true,
            showStepFiveTextOne: false,
            showStepFiveTextTwo: false,

            showStepSix: true,
            showStepSixText: false,
            StepSixRaceQuestion: {
                question: "Colours in a Rainbow",
                answers: [
                    {
                        answer: "Red",
                        isCorrect: true,
                    },
                    {
                        "answer": "Black",
                        "isCorrect": false
                    },
                    {
                        answer: "Orange",
                        isCorrect: true,
                    },
                    {
                        answer: "Yellow",
                        isCorrect: true,
                    },
                    {
                        answer: "White",
                        isCorrect: false,
                    }
                ]
            },
            showRaceQuestion: false,
            showRaceAnswers: false,
            StepSixPlayers: [
                {
                    avatar: 2,
                    pubData: {
                        answers: ["Red", "Orange", "White", "Yellow"],
                    }
                },
                {
                    avatar: 8,
                    pubData: {
                        answers: ["Red", "Orange", "Yellow",],
                    }
                },
                {
                    avatar: 10,
                    pubData: {
                        answers: ["Red", "Black", "Yellow"],
                    }
                },
                {
                    avatar: 6,
                    pubData: {
                        answers: ["Red", "Orange", "Yellow"],
                    }
                }
            ],
            showCorrectRaceAnswer: false,
            showRacePlayerAnswers: false,

            showStepSeven: true,
            showStepSevenTextOne: false,
            showStepSevenTextTwo: false,

            showStepEight: true,
            showStepEightText: false,
        };
        this.daveRevealAnimRef = React.createRef();
    }

    componentDidMount() {
        //this.initAudio();
        this.beginTutorial();
    }

    initAudio() {
        //this.preloadAudio();
        //Howler.volume(0.5);
    }

    preloadAudio() {
        for (let key in audio) {
            audio[key].loaded = new Howl({
                src: [audio[key].import],
                preload: true,
                loop: audio[key].loop,
                volume: audio[key].volume
            });
        }
    }

    playAudio(audio) {
        if (audio.loaded) audio.loaded.play();
    }

    getRandomElement(array) {
        return array[Math.random() * array.length >> 0];
    }

    beginTutorial() {
        setTimeout(() => {
            this.doStepOne();
        }, 2500);
    }

    doStepOne() {
        this.setState({ showStepOne: true, showStepOneTextOne: true, });
        setTimeout(() => {
            let playerTime = 0;
            let stepOnePlayers = [...this.state.stepOnePlayers];
            stepOnePlayers.forEach((x, i) => {
                setTimeout(() => {
                    stepOnePlayers[i].show = true;
                    this.setState({ stepOnePlayers, });
                    playerTime += (i * 200);
                }, (i * 200));
            });

            setTimeout(() => {
                this.setState({ showStepOneTextTwo: true });

                setTimeout(() => {
                    this.setState({ showStepOne: false, });

                    setTimeout(() => {
                        this.doStepTwo();
                    }, 1000);
                }, 3000);
            }, (2000 + playerTime));
        }, 1000);
    }

    doStepTwo() {
        this.setState({ showStepTwo: true, });
        setTimeout(() => {
            this.setState({ showStepTwoText: true, });

            setTimeout(() => {
                this.setState({ showStepTwoCategories: true, });

                setTimeout(() => {
                    this.setState({ showStepTwo: false });
                    this.doStepThree();
                }, 4000);
            }, 1500);

        }, 1000);
    }

    doStepThree() {
        this.setState({ showStepThree: true, });
        setTimeout(() => {
            this.setState({ showStepThreeText: true, });

            setTimeout(() => {
                this.setState({ showStepThreeQuestion: true, });

                setTimeout(() => {
                    this.setState({ showStepThreeAnswers: true, });

                    setTimeout(() => {
                        this.setState({ showPlayerAnswers: true, });

                        setTimeout(() => {
                            this.setState({ showCorrectAnswer: true, });

                            setTimeout(() => {
                                this.setState({ showStepThree: false, });
                                this.doStepFour();
                            }, 4000);
                        }, 2000);
                    }, 1000);
                }, 1000);
            }, 1000);
        }, 1000);
    }

    doStepFour() {
        this.setState({ showStepFour: true, });
        setTimeout(() => {
            this.setState({ showStepFourTextOne: true, });

            setTimeout(() => {
                this.setState({ showStepFourMechanics: true, });

                setTimeout(() => {
                    this.setState({ showStepFourTextTwo: true, });

                    setTimeout(() => {
                        this.setState({ showStepFour: false, });
                        this.doStepFive();
                    }, 4000);
                }, 3000);
            }, 1000);
        }, 1000);
    }

    doStepFive() {
        this.setState({ showStepFive: true, });
        setTimeout(() => {
            this.setState({ showStepFiveTextOne: true, });

            setTimeout(() => {
                this.setState({ showStepFiveTextTwo: true, });

                setTimeout(() => {
                    this.setState({ showStepFive: false, });
                    this.doStepSix();
                }, 4000);
            }, 1000);
        }, 1000);
    }

    doStepSix() {
        this.setState({ showStepSix: true, });
        setTimeout(() => {
            this.setState({ showStepSixText: true, });

            setTimeout(() => {
                this.setState({ showRaceQuestion: true, });

                setTimeout(() => {
                    this.setState({ showRaceAnswers: true, });

                    setTimeout(() => {
                        this.setState({ showRacePlayerAnswers: true, });

                        setTimeout(() => {
                            this.setState({ showCorrectRaceAnswer: true, });

                            setTimeout(() => {
                                this.setState({ showStepSix: false, });
                                this.doStepSeven();
                            }, 4000);
                        }, 2000);
                    }, 1500);
                }, 1000);
            }, 1000);
        }, 1000);
    }



    doStepSeven() {
        this.setState({ showStepSeven: true, });
        setTimeout(() => {
            this.setState({ showStepSevenTextOne: true, });

            setTimeout(() => {
                this.setState({ showStepSevenTextTwo: true, });

                setTimeout(() => {
                    this.setState({ showStepSeven: false, });
                    this.doStepEight();
                }, 5000);
            }, 1500);
        }, 1000);
    }


    doStepEight() {
        this.setState({ showStepEight: true, });
        setTimeout(() => {
            this.setState({ showStepEightText: true, });

            setTimeout(() => {
                this.setState({ showStepEight: false, });
                this.props.room.send("end_tutorial", {});
            }, 2000);
        }, 1000);
    }

    getVoteCount() {
        let count = 0;
        this.props.players.forEach((x) => {
            if (x.votedSkip) count++;
        });
        return count;
    }

    render() {
        return (
            <div id="tutorialContainer" className={styles.tutorialContainer}>
                <div className={styles.voteCount}>Skip Votes: {this.getVoteCount()} / {this.props.players.length}</div>
                <div className={`${styles.stepOne} ${styles.step} ${this.state.showStepOne && styles.show}`}>
                    <div className={`${styles.text} ${this.state.showStepOneTextOne && styles.show}`}>{this.state.stepOneTextOne}</div>
                    <div className={styles.playerRow}>
                        {
                            this.state.stepOnePlayers.map((x) => {
                                return <div className={`${styles.player} ${x.show && styles.show}`}>
                                    <Lottie
                                        options={getAvatarById(x.avatar).idleAnim}
                                        width="100%"
                                        height="100%"
                                        isClickToPauseDisabled={true}
                                    />
                                </div>
                            })
                        }
                    </div>
                    <div className={`${styles.text} ${this.state.showStepOneTextTwo && styles.show}`}>{this.state.stepOneTextTwo}</div>
                </div>

                <div className={`${styles.stepTwo} ${styles.step} ${this.state.showStepTwo && styles.show}`} style={{ width: "100%" }}>
                    <div className={`${styles.text} ${this.state.showStepTwoText && styles.show}`}>A random player starts by selecting a category...</div>
                    <div className={`${styles.categorySection} ${this.state.showStepTwoCategories && styles.show}`}>
                        <div className={styles.categoryContainer}>
                            <div className={styles.categorySelectorContainer}>
                                {
                                    this.state.stepTwoCategories.map((c, i) => {
                                        return <div key={i} className={`${styles.categoryOption} ${c.toString() === this.state.stepTwoSelectedCategory ? styles.selected : ""}`}>
                                            <p className={styles.categoryOptionText}>{c.toString()}</p>
                                        </div>;
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`${styles.stepThree} ${styles.step} ${this.state.showStepThree && styles.show}`}>
                    <div className={`${styles.text} ${this.state.showStepThreeText && styles.show}`}>Then everyone answers multiple choice questions...</div>
                    <div className={styles.questionSection}>
                        {
                            <div className={styles.questionAnswerContainer}>
                                <div className={`${styles.question} ${styles.normal} ${this.state.showStepThreeQuestion && styles.show}`}>
                                    <div className={styles.questionNumber}><p>{this.state.questionNumber}</p></div>
                                    {this.state.question.question}
                                </div>
                                {
                                    this.state.question.answers.map((a, i) => {
                                        return <div key={i} className={`${styles.answerOption} ${this.state.showStepThreeAnswers && styles.show} ${this.state.showCorrectAnswer && a.isCorrect ? styles.correctAnswer : ""}`}>
                                            <div className={`${styles.playerBox} ${styles.left}`}>
                                                {
                                                    this.state.stepThreePlayers.map((p, i) => {
                                                        if (a.answer == p.pubData.answer) {
                                                            if (i % 2 == 0) {
                                                                return <div className={`${styles.player} ${this.state.showPlayerAnswers && styles.show}`}>
                                                                    <Lottie
                                                                        options={getAvatarById(p.avatar).idleAnim}
                                                                        width="100%"
                                                                        height="100%"
                                                                        isClickToPauseDisabled={true}
                                                                    />
                                                                </div>
                                                            }
                                                        }
                                                    })
                                                }
                                            </div>
                                            <div className={`${styles.playerBox} ${styles.right}`}>
                                                {
                                                    this.state.stepThreePlayers.map((p, i) => {
                                                        if (a.answer == p.pubData.answer) {
                                                            if (i % 2 == 1) {
                                                                return <div className={`${styles.player} ${this.state.showPlayerAnswers && styles.show} ${styles.flip}`}>
                                                                    <Lottie
                                                                        options={getAvatarById(p.avatar).idleAnim}
                                                                        width="100%"
                                                                        height="100%"
                                                                        isClickToPauseDisabled={true}
                                                                    />
                                                                </div>
                                                            }
                                                        }
                                                    })
                                                }
                                            </div>
                                            <p className={styles.questionText}>{a.answer.toString()}</p>
                                        </div>;
                                    })
                                }
                            </div>
                        }
                    </div>
                </div>

                <div className={`${styles.stepFour} ${styles.step} ${this.state.showStepFour && styles.show}`}>
                    <div className={`${styles.text} ${this.state.showStepFourTextOne && styles.show}`}>Use your bonus actions to help you win...</div>
                    <div className={`${styles.bonusActions} ${this.state.showStepFourMechanics && styles.show}`}>
                        {
                            this.state.StepFourMechanics.map((m, i) => {
                                return <div className={styles.bonusActionWrapper}>
                                    <div className={styles.bonusAction}>
                                        <img className={styles.bonusActionIcon} src={m.icon} />
                                    </div>
                                    <p className={styles.bonusActionText}>{m.text}</p>
                                </div>
                            })
                        }
                    </div>
                    <div className={`${styles.text} ${this.state.showStepFourTextTwo && styles.show}`}>...but you can only use each one once per round!</div>
                </div>

                <div className={`${styles.stepFive} ${styles.step} ${this.state.showStepFive && styles.show}`} style={{ width: "100%", height: "100%", justifyContent: 'center', }}>
                    <div className={`${styles.text} ${this.state.showStepFiveTextOne && styles.show}`}>Complete 3 full rounds of 5 questions...</div>
                    <div className={`${styles.text} ${this.state.showStepFiveTextTwo && styles.show}`}>...to start the Race to the Bar!</div>
                </div>

                <div className={`${styles.stepSix} ${styles.step} ${this.state.showStepSix && styles.show}`}>
                    <div className={`${styles.text} ${this.state.showStepSixText && styles.show}`} style={{ margin: 0 }}>In the race, select all answers that apply<br></br>to the presented question...</div>
                    <div className={`${styles.questionSection} ${styles.race}`}>
                        <div className={styles.questionAnswerContainer}>
                            <div className={`${styles.question} ${styles.normal} ${this.state.showRaceQuestion && styles.show}`}>
                                {this.state.StepSixRaceQuestion.question}
                            </div>
                            {
                                this.state.StepSixRaceQuestion.answers?.map((a, i) => {
                                    return <div key={i} className={`${styles.answerOption} ${this.state.showRaceAnswers && styles.show} ${this.state.showCorrectRaceAnswer ? a.isCorrect ? styles.correct : "" : ""}`}>
                                        <p className={styles.questionText}>{a.answer.toString()}</p>
                                        <div className={`${styles.playerBox} ${styles.left}`}>
                                            {
                                                this.state.StepSixPlayers.map((p, pIndex) => {
                                                    if (p.pubData.answers.includes(a.answer)) {
                                                        if (pIndex % 2 == 0) {
                                                            return <div className={`${styles.player} ${this.state.showRacePlayerAnswers && styles.show}`}>
                                                                <Lottie
                                                                    options={getAvatarById(p.avatar).idleAnim}
                                                                    width="100%"
                                                                    height="100%"
                                                                    isClickToPauseDisabled={true}
                                                                />
                                                            </div>
                                                        }
                                                    }
                                                })
                                            }
                                        </div>
                                        <div className={`${styles.playerBox} ${styles.right}`}>
                                            {
                                                this.state.StepSixPlayers.map((p, pIndex) => {
                                                    if (p.pubData.answers.includes(a.answer)) {
                                                        if (pIndex % 2 == 1) {
                                                            return <div className={`${styles.player} ${this.state.showRacePlayerAnswers && styles.show}`}>
                                                                <Lottie
                                                                    options={getAvatarById(p.avatar).idleAnim}
                                                                    width="100%"
                                                                    height="100%"
                                                                    isClickToPauseDisabled={true}
                                                                />
                                                            </div>
                                                        }
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>

                <div className={`${styles.stepSeven} ${styles.step} ${this.state.showStepSeven && styles.show}`}>
                    <div className={`${styles.text} ${this.state.showStepSevenTextOne && styles.show}`}>Get at least one correct answer to move <br></br>towards the bar...</div>
                    <div className={`${styles.text} ${this.state.showStepSevenTextTwo && styles.show}`}>...but get one wrong, and don't move at all!</div>
                </div>

                <div className={`${styles.stepEight} ${styles.step} ${this.state.showStepEight && styles.show}`}>
                    <div className={`${styles.text} ${styles.larger} ${this.state.showStepEightText && styles.show}`} style={{ marginBottom: 0, }}>GOOD LUCK!</div>
                </div>
            </div>
        );
    }
}