import React, { Component } from 'react';

import styles from './LobbyStyles.module.scss';
import formStyles from "./FormStyles.module.scss";

import FormPage from './FormPage';

class ErrorModal extends Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        return (
            <div className={`${styles.screenOverlay} ${this.props.styles}`} >
                <div className={styles.screenOverlayBG}></div>
                <FormPage modal={true}>
                    <h2 className={formStyles.formTitle}>{this.props.title}</h2>
                    <p>{this.props.message}</p>
                    <div className={formStyles.buttonWrapper}>
                        <button onClick={() => this.props.callback()} className={`${styles.button} ${styles.alternate}`}>{this.props.callbackText}</button>
                        {
                            this.props.callbackText2 &&
                            <button onClick={() => this.props.callback2()} className={`${styles.button}`}>{this.props.callbackText2}</button>
                        }
                    </div>
                </FormPage>
            </div>
        );
    }
}


export default (ErrorModal);
