import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

const logError = (message, error) => {
    var configLogError = {
        method: 'post',
        url: API_URL + 'api/logging/log-error',
        headers: {
            'Content-Type': 'application/json'
        },
        data: {
            message,
            error
        }
    };

    return instance(configLogError)
        .then(function (response) {
            return response.data;

        }).catch(function (error) {
            console.log(error);
            return error;
        });
};

export default {
    logError
};
