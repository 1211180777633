import React, { Component } from 'react';

import styles from 'components/BonusActionsStyles.module.scss';

import CopyPlayerIcon from "images/copy-player.png";
import DoublePointsIcon from "images/double-points.png";
import RemoveOneIcon from "images/remove-one.png";


export default class BonusActions extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showCopyPlayerPopup: false,
            selecteCopyPlayerId: null,

            showDoublePointsPopup: false,

            showRemoveOnePopup: false,
        };
    }

    componentDidMount() {
    }

    openCopyPlayer = () => {
        if (this.props.player.pubData.usedCopyPlayer.length === 0) {
            this.setState({ showCopyPlayerPopup: true, showRemoveOnePopup: false, showDoublePointsPopup: false, });
        }
    }

    copyPlayerAnswer(playerId) {
        if (playerId === null) return;

        this.setState({ showCopyPlayerPopup: false, selecteCopyPlayerId: null, });
        this.props.room.send("use_copy_player", { playerIdToCopy: playerId });
    }

    openDoublePoints = () => {
        if (this.props.player.pubData.usedDoublePointsRound.length === 0) {
            this.setState({ showDoublePointsPopup: true, showCopyPlayerPopup: false, showRemoveOnePopup: false,});
        }
    }

    useDoublePoints = () => {
        this.setState({ showDoublePointsPopup: false, });
        this.props.room.send("use_double_points", {});
    }

    openRemoveOne = () => {
        if (this.props.player.pubData.usedRemoveOneAnswer.length === 0) {
            this.setState({ showRemoveOnePopup: true, showDoublePointsPopup: false, showCopyPlayerPopup: false, });
        }
    }

    usedRemoveOne = () => {
        this.setState({ showRemoveOnePopup: false, });
        this.props.room.send("use_remove_one_answer", {});
    }

    render() {
        return (
            <>
                <div className={styles.bonusActions}>
                    {/*<div className={`${styles.bonusAction} ${this.props.player?.pubData.usedCopyPlayer.length === 0 && styles.active}`} onClick={this.openCopyPlayer}>*/}
                    {/*    <img className={styles.bonusActionIcon} src={CopyPlayerIcon} />*/}
                    {/*</div>*/}
                    <div className={`${styles.bonusAction} ${this.props.player?.pubData.usedDoublePointsRound.length === 0 && styles.active}`} onClick={this.openDoublePoints}>
                        <img className={styles.bonusActionIcon} src={DoublePointsIcon} />
                    </div>
                    <div className={`${styles.bonusAction} ${this.props.player?.pubData.usedRemoveOneAnswer.length === 0 && styles.active}`} onClick={this.openRemoveOne}>
                        <img className={styles.bonusActionIcon} src={RemoveOneIcon} />
                    </div>
                </div>
                {
                    this.state.showCopyPlayerPopup &&
                    <div className={styles.popup}>
                        <div className={styles.popupContent}>
                            <div className={styles.popupTitle}>
                                <h3>Select a Player to Copy</h3>
                            </div>
                            <div className={styles.popupBody}>
                                {
                                    this.props.players.map((player, index) => {
                                        if (player.id === this.props.player.id) return null;
                                        return (
                                            <div className={`${styles.copyPlayerPopupPlayer} ${this.state.selecteCopyPlayerId === player.id && styles.selected}`} key={index} onClick={() => this.setState({ selecteCopyPlayerId: player.id })}>
                                                {player.name}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className={styles.popupButtons}>
                                <button className={`${styles.popupButton}`} onClick={() => this.setState({ showCopyPlayerPopup: false, selecteCopyPlayerId: null, })}>Cancel</button>
                                <button className={`${styles.popupButton} ${styles.alt}`} onClick={() => this.copyPlayerAnswer(this.state.selecteCopyPlayerId)}>Confirm</button>
                            </div>
                        </div>
                    </div>
                }
                {
                    this.state.showDoublePointsPopup &&
                    <div className={styles.popup}>
                        <div className={styles.popupContent}>
                            <div className={styles.popupTitle}>
                                <h3>Double Points</h3>
                            </div>
                            <div className={styles.popupBody}>
                                <p>Are you sure you want to use your double points bonus for this question?</p>
                            </div>
                            <div className={styles.popupButtons}>
                                <button className={`${styles.popupButton}`} onClick={() => this.setState({ showDoublePointsPopup: false, })}>No</button>
                                <button className={`${styles.popupButton} ${styles.alt}`} onClick={() => this.useDoublePoints()}>Yes</button>
                            </div>
                        </div>
                    </div>
                }
                {
                    this.state.showRemoveOnePopup &&
                    <div className={styles.popup}>
                        <div className={styles.popupContent}>
                            <div className={styles.popupTitle}>
                                <h3>Remove One</h3>
                            </div>
                            <div className={styles.popupBody}>
                                <p>Are you sure you want to remove one answer for this question?</p>
                            </div>
                            <div className={styles.popupButtons}>
                                <button className={`${styles.popupButton}`} onClick={() => this.setState({ showRemoveOnePopup: false, })}>No</button>
                                <button className={`${styles.popupButton} ${styles.alt}`} onClick={() => this.usedRemoveOne()}>Yes</button>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }

}