import React, { Component } from 'react';

export class Timer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            className: props.className,
            seconds: props.seconds,
            strokeWidth: props.strokeWidth,
            strokeColour: props.strokeColour,
            length: 0,
            offset: 0,
            increment: 0,
        }
    }

    componentDidMount() {
        const length = (this.divElement.clientHeight + this.divElement.clientWidth) * 2;
        this.setState({ length: length, increment: length / this.props.seconds });
        this.startTimer();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.pauseTimer === true) {
            this.resetInterval();
        }
    }

    componentWillUnmount() {
        this.resetInterval();
    }

    startTimer() {
        this.resetInterval();
        this.intervalCtrl = setInterval(() => {
            this.setState({ offset: this.state.offset + this.state.increment });
            (this.state.length - this.state.offset) <= 0 && this.resetInterval();
        }, 1000);
    }

    resetInterval() {
        this.intervalCtrl && clearInterval(this.intervalCtrl)
        this.intervalCtrl = null
    }

    render() {
        return (
            <div 
                className={this.props.className}
                ref={(divElement) => { this.divElement = divElement }}
                style={{ overflow: "hidden", borderRadius: "1rem" }}>
                <svg id="rectangle-timer" viewBox="0 0 100 100" preserveAspectRatio="none" style={{ width: "100%", height: "100%" }}>
                    <path id="timer-path" style={{ transition: "stroke-dashoffset 1s linear" }} d="M50,0L100,0 100,100 0,100 0,0 50,0" fill="none" stroke={this.state.strokeColour} strokeWidth={this.state.strokeWidth} strokeDasharray={this.state.length} strokeDashoffset={this.state.offset} vectorEffect="non-scaling-stroke" />
                </svg>
            </div>
        )
    }
}

export default Timer;