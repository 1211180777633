import background from "../images/background.png";
import backgroundv2 from "../images/background-v2.png";
import barv1 from "../images/bar-v1.png";
import beerBottle from "../images/beer-bottle.png";
import bottle from "../images/bottle.png";
import copyPlayer from "../images/copy-player.png";
import cross from "../images/cross.png";
import doublePoints from "../images/double-points.png";
import fullWoodBg from "../images/full-wood-bg.jpg";
import fullWoodBgPng from "../images/full-wood-bg.png";
import glass from "../images/glass.png";
import logo from "../images/logo.png";
import pint from "../images/pint.png";
import removeOne from "../images/remove-one.png";
import stein from "../images/stein.png";
import stool from "../images/stool.png";
import tablesLeft from "../images/tables_left.png";
import tablesRight from "../images/tables_right.png";
import tempTable from "../images/temp-table.png";
import tick from "../images/tick.png";
import wine from "../images/wine.png";
import lobbyFullscreen from "../images/Host/lobby_fullscreen.png";
import lobbyHelp from "../images/Host/lobby_help.png";
import lobbyMuted from "../images/Host/lobby_muted.png";
import lobbyUnmuted from "../images/Host/lobby_unmuted.png";


const images = [
    background,
    backgroundv2,
    barv1,
    beerBottle,
    bottle,
    copyPlayer,
    cross,
    doublePoints,
    fullWoodBg,
    fullWoodBgPng,
    glass,
    logo,
    pint,
    removeOne,
    stein,
    stool,
    tablesLeft,
    tablesRight,
    tempTable,
    tick,
    wine,
    lobbyFullscreen,
    lobbyHelp,
    lobbyMuted,
    lobbyUnmuted,
];

const fonts = [
    { font: "GearedSlab", path: `https://${window.location.host}/fonts/GearedSlab.ttf` },
    { font: "GearedSlab-Bold", path: `https://${window.location.host}/fonts/GearedSlab-Bold.ttf` },
    { font: "GearedSlab-Extrabold", path: `https://${window.location.host}/fonts/GearedSlab-Extrabold.ttf` },
    { font: "GearedSlab-Light", path: `https://${window.location.host}/fonts/GearedSlab-Light.ttf` },
];

export default {
    images,
    fonts,
};