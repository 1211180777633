import React, { Component } from 'react';
import Lottie from 'react-lottie';

import getAvatarById from "constants/avatars";
import styles from "components/PlayerStyles.module.scss";
import TickImg from "images/tick.png";
import CrossImg from "images/cross.png";
import StoolImg from "images/stool.png";
import DoublePointsIcon from "images/double-points.png";

export default class Player extends Component {

    constructor(props) {
        super(props);
        let fontSize = this.getFontSize(props.player);
        this.state = {
            fontSize: fontSize,
            show: props.show,
            className: props.className,
            moveToBar: false,
            movePos: 0,
        }
    }

    componentDidMount() {
        window.addEventListener("resize", () => {
            let movePos = this.getMovePosition(this.props.player.moveIndex);
            this.setState({ movePos });
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", () => {
            let movePos = this.getMovePosition(this.props.player.moveIndex);
            this.setState({ movePos });
        });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ fontSize: this.getFontSize(nextProps.player), });
    }


    getFontSize(player) {
        if (player) {
            let name = player.name;
            let fontSize = "";
            if (name.length <= 3) {
                fontSize = "large";
            } else if (name.length <= 6) {
                fontSize = "medium";
            } else {
                fontSize = "small";
            }
            return fontSize;
        } else {
            return "medium";
        }
    }

    calculateDistanceFromComponent = (componentOneId, componentTwoId) => {
        let componentOne = document.getElementById(componentOneId);
        let componentTwo = document.getElementById(componentTwoId);
        if (!componentOne || !componentTwo) {
            return;
        }
        let componentOneRect = componentOne.getBoundingClientRect();
        let componentTwoRect = componentTwo.getBoundingClientRect();
        let centerOne = componentOneRect.x + componentOneRect.width / 2;
        let centerTwo = componentTwoRect.x + componentTwoRect.width / 2;
        let distance = Math.abs(centerOne - centerTwo);

        //let distance = Math.sqrt(Math.pow(componentOneRect.x - componentTwoRect.x, 2) + Math.pow(componentOneRect.y - componentTwoRect.y, 2));
        console.log("Distance between " + componentOneId + " and " + componentTwoId + " is " + distance + "px");
        return distance;
    }

    getMovePosition(moveIndex) {
        if (moveIndex === 0) return 0;

        let distance = 0;
        distance = this.calculateDistanceFromComponent(`potato-anchor-${this.props.player.id}`, `${this.props.left ? "left" : "right"}-segment-${moveIndex}`);
        return distance;
    }

    answerIsCorrect = (answer, index) => {
        let answerIndex = this.props.raceAnswerIndexes.indexOf(index);
        return answer.isCorrect === this.props.player.pubData.raceAnswers[answerIndex];
    }

    doublePointsActive = () => {
        return this.props.player.pubData.usedDoublePointsRound.length > 0 && this.props.player.pubData.usedDoublePointsRound[0] == this.props.roundNumber && this.props.player.pubData.usedDoublePointsRound[1] == this.props.questionNumber;
    }

    copyPlayerActive = () => {
        return this.props.player.pubData.usedCopyPlayer.length > 0 && this.props.player.pubData.usedCopyPlayer[0] == this.props.roundNumber && this.props.player.pubData.usedCopyPlayer[1] == this.props.questionNumber;
    }

    render() {
        return (
            <div id={`player-${this.props.player.id}`} className={`${this.props.className} ${styles.player} ${this.props.left ? "" : styles.reverse} ${this.props.show ? styles.show : ""}`}>
                <div id={`potato-${this.props.player.id}`} className={`${styles.potato} `} >
                    <div id={`potato-anchor-${this.props.player.id}`} className={styles.potatoAnchor}></div>
                    {/*<div className={`${styles.potatoInner} ${this.props.isRace && styles.raceFormat}`}>*/}
                    <div id={`potato-move-${this.props.player.id}`} className={`${styles.potatoInner} ${this.props.isRace && styles.raceFormat} ${styles[`move-${this.props.player.moveAmount}`]}`} style={{ transform: `translateX(${this.props.isRace ? this.getMovePosition(this.props.player.moveIndex) : 0}px)` }}>
                        <div key={this.props.isRace ? this.getMovePosition(this.props.player.moveIndex) : 0} className={`${styles.potatoInner} ${styles[`bouncy-${this.props.player.moveAmount}`]}`}>
                            <Lottie
                                options={getAvatarById(this.props.player.avatar).idleAnim}
                                width="100%"
                                height="100%"
                                isClickToPauseDisabled={true}
                            />
                            {/*<div className={`${styles.raceAnswerBox} ${this.props.showRaceAnswers && styles.show}`}>*/}
                            {/*    {*/}
                            {/*        this.props.raceQuestion.answers?.map((answer, index) => {*/}
                            {/*            if (this.props.raceAnswerIndexes.includes(index)) {*/}
                            {/*                return <div id={`${answer.answer}`} key={index} className={`${styles.raceAnswer}`}>*/}
                            {/*                    <img alt={`answer-val-${index}`} src={this.answerIsCorrect(answer, index) ? TickImg : CrossImg} style={{ height: "100%", width: "auto" }} />*/}
                            {/*                </div>*/}
                            {/*            }*/}
                            {/*        })*/}
                            {/*    }*/}
                            {/*</div>*/}
                        </div>
                        </div>
                    {/*</div>*/}
                    <img className={`${styles.stool} ${this.props.isRace && styles.raceFormat}`} src={StoolImg} alt={`${this.props.player.name}-stool`} />
                </div>
                <div className={`${styles.textSection} ${this.props.showCorrectAnswer && this.props.player.pubData.isCorrect && styles.highlight} ${this.props.isRace && styles.hide}`}>
                    <div className={`${styles.readyBox} ${this.props.player.pubData.submitted && styles.show}`}>{this.copyPlayerActive() ? "COPIED" : "READY"}</div>
                    <div className={`${styles.name} ${this.props.player.name.length >= 6 ? this.props.player.name.length >= 9 ? styles.small : styles.medium : styles.large}`}>{this.props.player.name}</div>
                    <div className={`${styles.points}`}>{this.props.player.pubData.score} Points</div>
                    <div className={`${styles.doublePointsIcon} ${this.doublePointsActive() && styles.show}`}>
                        <img className={styles.icon} src={DoublePointsIcon} />
                    </div>
                </div>
            </div>
        )
    }
}